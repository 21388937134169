import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"
import { Link } from "gatsby"
import Img from "gatsby-image"

export const Container = styled(GridContainer)`
  ${media.lessThan("medium")`
    display: none;
  `}
`

export const Background = styled(Img)`
  position: absolute !important;
  width: 100vw;
  height: 800px;
  top: 0;
  z-index: -1;
  object-fit: cover;
  align-self: center;
`

export const Section = styled.section`
  display: flex;
  justify-content: center;

`

export const Home = styled.div`
  width: 500px;
  height: 900px;
`

export const Logo = styled(Img)`
  width: 220px;
  height: 52px;
`

export const TitleHome = styled.h2`
  font-size: 44px;
  font-weight: 800;
  line-height: 1.4;
  color: ${props => props.theme.colors.white};
`

export const DescriptionHome = styled.p`
  margin-top: 35px;
  width: 400px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.white};
`

export const Mocks = styled.div`
  width: 676px;
  height: 652px;
  z-index: 99999;
  margin-left: -100px;
  margin-top: 90px;
`

export const Image = styled(Img)`
  margin-top: 20px;
`

export const Simulator = styled.div`
  margin-left: 200px;
  margin-top: 50px;
  flex-direction: column;
  justify-content: center;
  width: 370px;
  height: 35rem;
`

export const Menu = styled.div`
  margin-left: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 370px;
  height: 4rem;
  margin-top: 80px;
`

export const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

`

export const Links = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50%;
`

export const FAQ = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.greenDarker};
`

export const App = styled.button`
  width: 174px;
  height: 48px;
  border-radius: 10px;
  border: solid 1px ${props => props.theme.colors.greenDarker};
  background-color: #ffffff;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${props => props.theme.colors.greenDarker};
  }
`

export const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 0.6px;
  color: #ffb504;
  text-transform: uppercase;
`

export const H2 = styled.h2`
  margin-top: 8px;
  margin-bottom: 28px;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.36;
  color: ${props => props.theme.colors.charcoal};
`

export const Observations = styled.div`
  opacity: 0.7;
  font-size: 8px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 40px;
  color: ${props => props.theme.colors.charcoal};
`

export const ContainerVideo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`

export const ContainerSimulator = styled.div`
  justify-content: flex-start;
  flex-direction: column;
  width: 500px;
  margin-right: 550px;
`

export const Button = styled.div`
  display: flex;
  border-radius: 10px;
  width: 400px;
  height: 48px;
  background-color: ${props => props.theme.colors.mango};
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  :hover {
    background-color: ${props => props.theme.colors.mango};
    opacity: 0.5;
    cursor: pointer;
  }

  h3 {
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  }
`