import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import iconCrisp from "../../images/ico-duo-crisp.svg"
import iconZap from "../../images/ico-duo-zap.svg"
import iconCalendar from "../../images/ico-duo-calendar.svg"

import {
  Container,
  Section,
  Title,
  Description,
  Chats,
  Chat,
  Icon,
  Text,
  How,
  Mocks,
  Phone,
  Others,
  Optional,
} from "./styles"

export default function Digital() {
  const {
    PhoneMock,
    HomeMock,
    IndicateMock,
    DigitalMock,
  } = useStaticQuery(graphql`
    query {
      PhoneMock: file(relativePath: { eq: "phone-mock.png" }) {
        childImageSharp {
          fluid(maxWidth: 259, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      HomeMock: file(relativePath: { eq: "mock-home.png" }) {
        childImageSharp {
          fluid(maxWidth: 217, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      IndicateMock: file(relativePath: { eq: "mock-indicate.png" }) {
        childImageSharp {
          fluid(maxWidth: 217, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      DigitalMock: file(relativePath: { eq: "mock-digital.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container fluid>
      <Section>
        <How>
          <Title>
            Somos 100% digital, mas <span>perto de você.</span>
          </Title>
          <Description>
            <span>Simule, contrate e resolva</span> tudo pelo aplicativo Veriza.
            Nossa equipe estará disponível para te atender
            sempre que necessário. <strong>Manteremos contato:</strong>
          </Description>
          <Chats>
            <Chat>
              <Icon src={iconCrisp} />
              <Text>Pelo chat online</Text>
            </Chat>
            <Chat>
              <Icon src={iconZap} />
              <Text>Pelo WhatsApp</Text>
            </Chat>
          </Chats>
        </How>
        <Mocks>
          <Phone fluid={PhoneMock.childImageSharp.fluid} />
          <Others fluid={HomeMock.childImageSharp.fluid} />
          <Others fluid={IndicateMock.childImageSharp.fluid} />
          <Optional fluid={DigitalMock.childImageSharp.fluid} />
        </Mocks>
      </Section>
    </Container>
  )
}
