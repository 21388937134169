import React, { useState, useEffect } from "react"

import Media from "../../images/digital.png"
import AnchorLink from "react-anchor-link-smooth-scroll"

import {
  Container,
  Section,
  Button,
} from "./styles"

export default function Hook() {
  const [href, setHref] = useState(null)

  const checkWidth = w => {
    if (w > 770) {
      setHref('#simulador')
    } else {
      setHref('#simulador-mobile')
    }
  }

  useEffect(() => {
    const width = document.body.clientWidth;
    checkWidth(width)

    window.addEventListener('resize', e => {
      const _width = e.target.outerWidth;
      checkWidth(_width)
    })
  }, [])

  return (
    <Container>
      <Section>
        <img src={Media} />
        <div className="content">
          <h1>Vemos no crédito uma solução</h1>
          <p>A Veriza nasceu para ajudar os milhões de microempreendedores brasileiros que não têm acesso a crédito. Inicie a sua solicitação.</p>
          <AnchorLink href="#simulador" style={{ textDecoration: "none" }}>
            <h3>Eu quero simular e solicitar</h3>
          </AnchorLink>
        </div>
      </Section>
    </Container>
  )
}
