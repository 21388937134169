import React, { useEffect, useState } from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import PitchAndSimulator from "../sections/pitchAndSimulator"
import Pitch from "../sections/pitch"
import Simulator from "../sections/simulator"
import Group from "../sections/group"
import Compare from "../sections/compare"
import How from "../sections/how"
import Media from "../sections/media"
import Digital from "../sections/digital"
import Hook from "../sections/hook"
import Contact from "../sections/contact"
import Footer from "../sections/footer"

const IndexPage = ({ location }) => {
  const [trackingUtm, setTrackingUtm] = useState({});

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const body = {
      origin: 'LP - Lider',
      utm_source: params.get('utm_source') || null,
      utm_medium: params.get('utm_medium') || null,
      utm_campaign: params.get('utm_campaign') || null,
      utm_term: params.get('utm_term') || null,
      utm_content: params.get('utm_content') || null,
    }

    setTrackingUtm(body);
    localStorage.setItem('tracking', JSON.stringify(body));
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <PitchAndSimulator trackingUtm={trackingUtm}/>
      <Pitch />
      <Simulator trackingUtm={trackingUtm} />
      <Group />
      <Compare />
      <How />
      <Digital />
      <Media />
      <Hook />
      <Contact />
      <Footer />
    </Layout>
  )
};

export default IndexPage
