import React from "react"

import ZapIcon from "../../images/group-11.png"
import ArrowFAQ from "../../images/arrow-right.svg"
import PlayStore from "../../images/playstore.png"
import AppStore from "../../images/appstore.png"

import {
  Container,
  SectionContent,
  ContactContent,
  CallToActionContent,
  PlayStoreImage,
  Section,
  Title,
  Description,
  Phone,
  Crisp,
  Zap,
  Button,
  FAQ,
  Arrow,
} from "./styles"

export default function Contact() {
  return (
    <Container>
      <SectionContent>
        <CallToActionContent>
          <h1>Baixe já o app Veriza</h1>
          <PlayStoreImage
            src={AppStore}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/veriza/id1550168186",
                "_blank"
              )
            }
          />
          <PlayStoreImage
            src={PlayStore}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=br.com.veriza.app&hl=pt_BR",
                "_blank"
              )
            }
          />
        </CallToActionContent>
      </SectionContent>
      <SectionContent>
        <ContactContent>
          <Title>Tem dúvidas?</Title>
          <Crisp>
            Estamos ao seu dispor! Fale conosco através do nosso{" "}
            <strong> Atendimento Online</strong> clicando no botão de chat no
            canto inferior da tela.
          </Crisp>
          {/* <Description>
            Ou mande um Olá no <strong>Whatsapp</strong>!
          </Description>
          <Phone>
            <span>61</span> 98359-6111{" "}
            <Zap
              src={ZapIcon}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=556183699736&text=Ol%C3%A1!%20Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Veriza.%20",
                  "_blank"
                )
              }
            />
          </Phone> */}
          <FAQ to="/faq">
            <button>
              <p>Acessar perguntas frequentes</p>
            </button>
          </FAQ>
        </ContactContent>
      </SectionContent>
    </Container>
  )
}
