import React, { useState, useRef, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactModal from 'react-modal';

import Menu from "../../images/menu.png"

import {
  Gradient,
  Logo,
  Title,
  Description,
  MenuIcon,
  Header,
  Background,
  Button,
  Context
} from "./styles"

import HamburgerMenuMobile from "../../components/hamburgerMenu";
import Alert from "../alert"

const Pitch = ({ simulatorRef }) => {

  const [visible, setVisible] = useState(false);
  const { Veriza, Camila } = useStaticQuery(graphql`
    query {
      Veriza: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 220, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      Camila: file(relativePath: { eq: "camila-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 676, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const openModal = () => {
    setVisible(true);
  }

  const closeModal = (flag) => {
    setVisible(flag);
  }

  const ModalStyles = {
    content: {
      background: "#f0fbea",
      position: "absolute",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      border: "0px"
    }
  }

  const scrollToSimulator = useCallback(() => {
    simulatorRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [simulatorRef]);

  return (
    <Context>
      <Alert />
      <Header>
        <Logo fluid={Veriza.childImageSharp.fluid} />
        <MenuIcon src={Menu} onClick={() => openModal()} />
      </Header>
      <Background fluid={Camila.childImageSharp.fluid} />
      <Title>
        Crédito em grupo 100% pelo celular !
      </Title>
      <Description>
        Empréstimo facilitado para microempreendedores. Vamos te ajudar a acelerar o seu negócio!
      </Description>

      <Button onClick={scrollToSimulator}>
        <h3>Simular agora</h3>
      </Button>

      <ReactModal isOpen={visible} onRequestClose={closeModal} style={ModalStyles}>
        <>
          <HamburgerMenuMobile closeCallBack={setVisible} />
        </>
      </ReactModal>
    </Context>
  )
}

export default Pitch
