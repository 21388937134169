import React, { useCallback, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Container,
  Section,
  Background,
  Home,
  Logo,
  TitleHome,
  DescriptionHome,
  Mocks,
  Image,
  Simulator,
  Menu,
  Links,
  FAQ,
  Title,
  H2,
  Observations,
  LinksContainer,
  ContainerSimulator,
  Button
} from "./styles"

import SimulatorComponent from "../../components/simulator"
import Alert from "../alert"

const PitchAndSimulator = ({ trackingUtm }) => {
  const simulatorRef = useRef(null);

  const { Veriza, Mock, Camila } = useStaticQuery(graphql`
    query {
      Veriza: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 220, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      Mock: file(relativePath: { eq: "mock-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 676, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      Camila: file(relativePath: { eq: "camila-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 676, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const scrollToSimulator = useCallback(() => {
    simulatorRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [simulatorRef]);

  return (
    <Container fluid id="pitch">
      <Section style={{ flexDirection: 'column', height: 820, justifyContent: 'flex-start' }}>
        <Background fluid={Camila.childImageSharp.fluid} />
        <Alert />
        <Section>
          <div style={{ width: 500 }}>
            <Logo fluid={Veriza.childImageSharp.fluid} />
          </div>
          <Menu>
            <LinksContainer>
              <Links>
                <FAQ to="/">Início</FAQ>
              </Links>
              <Links>
                <FAQ to="/faq">Perguntas frequentes</FAQ>
              </Links>
              <Links>
                <FAQ to="http://blog.veriza.com.br/">Blog Veriza</FAQ>
              </Links>
            </LinksContainer>
          </Menu>
        </Section>
        <Section>
          <ContainerSimulator>
            <TitleHome>
              Crédito <span>em grupo </span>
              100% pelo celular.
            </TitleHome>
            <DescriptionHome>
              Empréstimo facilitado para microempreendedores. Vamos te ajudar a acelerar o seu negócio!
            </DescriptionHome>

            <Button onClick={scrollToSimulator}>
              <h3>Simular agora</h3>
            </Button>
          </ContainerSimulator>
        </Section>
      </Section>

      <Section>
        <Home>
          <Mocks>
            <Image fluid={Mock.childImageSharp.fluid} />
          </Mocks>
        </Home>
        <Simulator id="simulador" ref={simulatorRef}>
          <Title>Simule e solicite</Title>
          <H2>De quanto você precisa?</H2>
          <SimulatorComponent trackingUtm={trackingUtm} />
          <Observations>
            *Valores das parcelas são aproximados, outras taxas podem ser aplicadas.
            Verificar condições de pagamento e acordos contratuais.
          </Observations>
        </Simulator>
      </Section>
    </Container>
  )
}

export default PitchAndSimulator
