import React from "react"
import "swiper/swiper-bundle.min.css"
import Desktop from "./desktop"
import Mobile from "./mobile"

import { Container, DesktopContainer, MobileContainer } from "./styles"

export default function How() {
  return (
    <Container>
      <DesktopContainer>
        <Desktop />
      </DesktopContainer>
      <MobileContainer>
        <Mobile />
      </MobileContainer>
    </Container>
  )
}
