import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

import ArrowSVG from "../../images/arrow.svg"

import {
  Container,
  Title,
  Steps,
  Step,
  Name,
  Description,
  StepSubTitle,
  StepDescription,
  Arrow,
  StepTitleDesktop,
  StepImageDesktop,
} from "./styles"

export default function Desktop() {
  const {
    SetpOneDesktop,
    SetpTwoDesktop,
    SetpThreeDesktop,
    SetpFourDesktop,
    SetpFiveDesktop,
  } = useStaticQuery(graphql`
    query {
      SetpOneDesktop: file(relativePath: { eq: "setp-one-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 257, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpTwoDesktop: file(relativePath: { eq: "setp-two-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 257, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpThreeDesktop: file(relativePath: { eq: "setp-three-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 257, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpFourDesktop: file(relativePath: { eq: "setp-four-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 257, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpFiveDesktop: file(relativePath: { eq: "setp-five-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 257, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const styles = {
    cards: {
      width: "23%",
    },
    arrow: {
      width: "4%",
      alignSelf: "center",
      padding: "10px",
    },
    swiper: {
      width: "85%",
      marginBottom: 50,
      paddingTop: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }

  return (
    <Container>
      <Title>
        A Veriza <br />
        funciona assim:
      </Title>
      <Swiper
        slidesPerView={"auto"}
        style={styles.swiper}
      >
        <Steps>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Name>
                <StepImageDesktop
                  fluid={SetpOneDesktop.childImageSharp.fluid}
                ></StepImageDesktop>
              </Name>
              <Description>
                <StepSubTitle>PASSO 1</StepSubTitle>
                <StepTitleDesktop>
                  Simule e solicite pelo celular
                </StepTitleDesktop>
                <StepDescription>
                  Simule o seu empréstimo e faça a solicitação.
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <StepImageDesktop
                fluid={SetpTwoDesktop.childImageSharp.fluid}
              ></StepImageDesktop>
              <Description>
                <StepSubTitle>PASSO 2</StepSubTitle>
                <StepTitleDesktop>
                  Convide amigos<br></br> para o grupo
                </StepTitleDesktop>
                <StepDescription>
                  Convide 2 pessoas com <strong>bom histórico de crédito</strong> e que queiram te ajudar
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Name>
                <StepImageDesktop
                  fluid={SetpFiveDesktop.childImageSharp.fluid}
                ></StepImageDesktop>
              </Name>
              <Description>
                <StepSubTitle>PASSO 3</StepSubTitle>
                <StepTitleDesktop>Seus amigos se cadastram</StepTitleDesktop>
                <StepDescription>
                  Seus 2 avalistas se cadastram por meio de um link.
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <StepImageDesktop
                fluid={SetpFourDesktop.childImageSharp.fluid}
              ></StepImageDesktop>
              <Description>
                <StepSubTitle>PASSO 4</StepSubTitle>
                <StepTitleDesktop>Análise</StepTitleDesktop>
                <StepDescription>
                  Vamos analisar sua solicitação e dar uma resposta em breve.
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Name>
                <StepImageDesktop
                  fluid={SetpThreeDesktop.childImageSharp.fluid}
                ></StepImageDesktop>
              </Name>
              <Description>
                <StepSubTitle>PASSO 5</StepSubTitle>
                <StepTitleDesktop>Invista em seu negócio!</StepTitleDesktop>
                <StepDescription>
                  Com o dinheiro na conta você já pode investir no seu negócio.
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
        </Steps>
      </Swiper>
    </Container>
  )
}
